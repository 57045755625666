import { Link } from "gatsby"
import React from "react"
const Footer = () => {
  return (
    <footer className="bg-gray-800 p-4 ">
      <div className="flex flex-col mt-4 md:mt-10 md:flex-row">
        <div className="hidden ml-0 lg:ml-20 md:block w-full md:w-1/2">
          <p className="font-serif text-2xl text-green-500 mb-4">Hajebo</p>
          <p className="text-xl text-gray-300 ">
            Hajebo adalah blog yang membahas hal-hal mengenai hewan, resep
            masakan dll berdasarkan pengalaman, cobaan dan hinaan
          </p>
        </div>
        <div className="mx-2 text-center md:text-right w-full md:w-1/2 text-md md:text-xl lg:mx-28">
          <Link
            to="/about/"
            className="text-gray-200 mx-2 border-b-2 pb-2 border-gray-300"
          >
            About
          </Link>
          <Link
            to="/disclaimer/"
            className="text-gray-200 mx-2 border-b-2 pb-2 border-gray-300"
          >
            Disclaimer
          </Link>
          <Link
            to="/kebijakan-privasi/"
            className="text-gray-200 mx-2 border-b-2 pb-2 border-gray-300"
          >
            Kebijakan privasi
          </Link>
        </div>
      </div>
      <div className="text-center text-md md:text-xl w-full mx-4 my-8 text-gray-400">
        <p>
          Copyright © 2020 - {new Date().getFullYear()} Hajebo.
          <br />
          Made with &#10084; Pati{" "}
        </p>
        <a href="https://www.arasitek.com">
          Jasa arsitek, Kontraktor dan Desain interior
        </a>
      </div>
    </footer>
  )
}

export default Footer
