import React, { useState } from "react"
import { IoIosMenu } from "react-icons/io"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Logo from "../schema/logo"
const Submenu = ({ to, nama }) => {
  return (
    <button className="hover:bg-blue-400 bg-green-300 font-semibold active:bg-blue-300 rounded-lg m-2 border-2 py-2 px-2 flex border-green-600">
      <Link to={to}>{nama}</Link>
    </button>
  )
}

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false)
  const logo = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "hajebo-logo-bw.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)
  return (
    <header className="bg-green-500 z-10 sticky top-0 transition-transform border-green-500">
      <div className="flex flex-row">
        <div className="flex-grow ">
          <div>
            <Link to="/">
              <GatsbyImage
                className="w-10 md:w-12 lg:w-14 mx-4 mt-2 lg:mx-20"
                image={logo.icon.childImageSharp.gatsbyImageData}
                alt="Logo Hajebo"
                loading="eager"
              />
            </Link>
            <Logo />
          </div>
        </div>
        <div className="hidden md:block my-4 mr-20">
          <Link
            to="/peliharaan/"
            className="border-2 border-white m-2 hover:bg-white hover:text-green-400 hover:border-green-600 bg-green-500 text-white rounded-lg text-2xl px-2 py-1"
          >
            Peliharaan
          </Link>
          <Link
            to="/resep/"
            className="border-2 border-white m-2 hover:bg-white hover:text-green-400 hover:border-green-600 bg-green-500 text-white rounded-lg text-2xl px-2 py-1"
          >
            Resep
          </Link>
        </div>
        <button
          className="mx-2 block md:hidden "
          onClick={() => toggleExpansion(!isExpanded)}
          aria-label="menu"
        >
          <IoIosMenu size="32" color="white" />
        </button>
      </div>
      <div
        role="menu"
        className={`${isExpanded ? `block` : `hidden`}`}
        aria-hidden={isExpanded ? false : true}
      >
        <nav className="bg-green-200 z-20 m-4 p-2 border-4 rounded-lg">
          <p className="text-2xl font-serif font-semibold mx-8 mt-8">
            Kategori:
          </p>
          <div className=" mx-2 flex flex-row flex-wrap px-6">
            <Submenu to="/peliharaan/" nama="Peliharaan" />
            <Submenu to="/resep/" nama="Resep" />
          </div>
          <Link
            to="/about/"
            className="border-b-4 mx-4 border-green-600 text-gray-500 font-medium text-2xl"
          >
            About
          </Link>
        </nav>
      </div>
    </header>
  )
}
export default Header
