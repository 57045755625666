import React from "react"
import { Script } from "gatsby"
import LayoutPage from "./layoutPage"
export const wrapPageElement = ({ element }) => {
  return (
    <LayoutPage>
      {element}
      <Script src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3587798764873063" crossorigin="anonymous" strategy="off-main-thread"/>
      </LayoutPage>
  )
}