import { Organization } from "schema-dts"
import React from "react"
import { JsonLd } from "react-schemaorg"

export function Logo({}) {
  return (
    <JsonLd<Organization>
      item={{
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "hajebo",
        url: "https://hajebo.com",
        logo: "https://hajebo.com/logo.png",
        email: "hajebo.com@gmail.com",
        location: "Indonesia",
      }}
    />
  )
}

export default Logo
