import React from "react"
import { Script } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import {Helmet} from "react-helmet"
const LayoutPage = props => {
  return (
    <div>
      <Script src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3587798764873063" crossorigin="anonymous"/>
      <Header />
        <main>{props.children}</main>
      <Footer />
    </div>
  )
}

export default LayoutPage
