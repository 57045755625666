exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-kebijakan-privasi-js": () => import("./../../../src/pages/kebijakan-privasi.js" /* webpackChunkName: "component---src-pages-kebijakan-privasi-js" */),
  "component---src-template-blog-js": () => import("./../../../src/template/blog.js" /* webpackChunkName: "component---src-template-blog-js" */),
  "component---src-template-blog-peliharaan-js": () => import("./../../../src/template/blogPeliharaan.js" /* webpackChunkName: "component---src-template-blog-peliharaan-js" */),
  "component---src-template-blog-resep-js": () => import("./../../../src/template/blogResep.js" /* webpackChunkName: "component---src-template-blog-resep-js" */),
  "component---src-template-peliharaan-js": () => import("./../../../src/template/peliharaan.js" /* webpackChunkName: "component---src-template-peliharaan-js" */),
  "component---src-template-resep-js": () => import("./../../../src/template/resep.js" /* webpackChunkName: "component---src-template-resep-js" */)
}

